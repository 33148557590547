import React from 'react';
import IndexLayout from 'layouts';
import styled from '@emotion/styled';
import { Components } from 'sitedata';
import LazyLoad from 'react-lazyload';
import TopSection from './TopSection';
import FeaturedSection from './FeaturedSection';
import BenefitsSection from './BenefitsSection';
import ContactSection from './ContactSection';
import Helmet from 'react-helmet';
import { info } from 'sitedata';
import { useStaticQuery, graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';



const IndexPage = () => {
  const images = useStaticQuery(graphql`
  {
    og: file(name: { eq: "cover-about" }, sourceInstanceName: { eq: "sitedataAssets" }) {
      childImageSharp {
        fluid(maxWidth: 1366, maxHeight: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    logo: file(name: { eq: "favicon" }, sourceInstanceName: { eq: "sitedataAssets" }) {
      publicURL
    }
  }
  `);
  const { t } = useTranslation();
  return (
    <IndexLayout>
      <Helmet>
        <title>{t('metatags.homepage.title')}</title>
        <meta name="description" content={t('metatags.homepage.description')} />
        <meta property="og:title" content={t('metatags.homepage.title')} />
        <meta property="og:description" content={t('metatags.homepage.description')} />
        <meta property="og:url" content={info.SITE_URL} />
        <meta property="og:image" content={info.SITE_URL + images.og.childImageSharp.fluid.src} />
        <meta property="twitter:title" content={t('metatags.homepage.title')} />
        <meta property="twitter:description" content={t('metatags.homepage.description')} />
        <meta property="twitter:url" content={info.SITE_URL} />
        <meta property="twitter:image" content={info.SITE_URL + images.og.childImageSharp.fluid.src} />
        <script type="application/ld+json">{`
        [
        {
          "@context": "https://schema.org",
          "@type": "Organization",
          "url": "${info.SITE_URL}",
          "logo": "${info.SITE_URL + images.logo.publicURL}",
          "email": "${t('footer.email')}",
          "address": [ {
            "@type": "PostalAddress",
            "addressLocality": "${t('address.city')+','+t('address.state')}",
            "postalCode": "${t('address.zip_code')}",
            "streetAddress": "${t('address.address1')}"
            } ],
          "contactPoint" : [ { 
            "@type" : "ContactPoint",
            "telephone" : "${t('footer.phone')}",
            "contactType" : "customer service"
          } ] }
        ]`}
        </script>
      </Helmet>
      <StyledHeader>
        <Components.Header isLight />
      </StyledHeader>
      <TopSection />
      <LazyLoad>
        <FeaturedSection />
      </LazyLoad>
      <BenefitsSection />
      <ContactSection />
      <Components.Footer />
    </IndexLayout>
  );
};

export default IndexPage;

const StyledHeader = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: black;
  .header {
    width: 100%;
  }
`;
